<template>
  <div v-if="permission.access">
    <b-row>
      <b-col xl="8">
        <b-card title="Company List" no-body>
          <b-card-body>
            <b-row class="justify-content-between">
              <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label class="mr-1">Show</label>
                <Select
                  v-model="search.paginate"
                  :clearable="false"
                >
                  <Option :value="10"> 10</Option>
                  <Option :value="30"> 30</Option>
                  <Option :value="50"> 50</Option>
                  <Option :value="80"> 80</Option>
                  <Option :value="100"> 100</Option>
                </Select>
              </b-col>
              <b-col cols="12" md="5">
                <Select
                  v-model="search.status"
                  @on-change="SearchData"
                  :clearable="true"
                  placeholder="Select Status"
                >
                  <Option :value="1"> Active</Option>
                  <Option :value="2"> Inactive</Option>
                </Select>
              </b-col>
              <b-col cols="12" md="5">
                <Input
                  v-model="search.search_data"
                  class="d-inline-block mr-1"
                  placeholder="Search name..."
                  type="text"
                />
              </b-col>
            </b-row>
          </b-card-body>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th class="text-center">Logo</th>
                  <th>Company</th>
                  <th>Location</th>
                  <th>Approval</th>
                  <th
                    class="text-center"
                    v-if="permission.edit || permission.delete"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="companies.data.length"
                  v-for="(company, index) in companies.data"
                  :key="index"
                >
                  <td class="align-middle text-center">
                    {{ companies.meta.from + index }}
                  </td>
                  <td class="align-middle text-center">
                    <img
                      :src="company.logo ? company.logo : getImage(160, 70)"
                      height="70"
                      alt="dd"
                    />
                  </td>
                  <td width="250px">
                    <p>
                      <b>{{ company.name ? company.name : "No data yet" }}</b>
                    </p>
                    <p>
                      <b>Email:</b>
                      {{ company.email ? company.email : "No data yet" }}
                    </p>
                    <p>
                      <b>Phone:</b>
                      {{ company.phone ? company.phone : "No data yet" }}
                    </p>
                  </td>
                  <td style="padding: 0.72rem 1rem">
                    <p>
                      {{ company.upazila ? company.upazila : "No data yet" }},
                      {{ company.district ? company.district : "No data yet" }},
                      {{ company.division ? company.division : "No data yet" }},
                      {{ company.address ? company.address : "No data yet" }}
                    </p>
                    <p></p>
                  </td>
                  <td style="padding: 0.72rem 1rem; width: 200px">
                    <p>
                      <b>Approved By:</b>
                      {{
                        company.approved_by
                          ? company.approved_by
                          : "not approved"
                      }}
                    </p>
                    <p>
                      <b>Approval: </b>
                      <span
                        style="margin-bottom: 2px"
                        :class="
                          company.is_approved === 'Approved'
                            ? 'badge badge-success'
                            : company.is_approved === 'Pending'
                            ? 'badge badge-primary'
                            : company.is_approved === 'Rejected'
                            ? 'badge badge-danger'
                            : ''
                        "
                      >
                        {{
                          company.is_approved === "Approved"
                            ? "Approved"
                            : company.is_approved === "Pending"
                            ? "Pending"
                            : company.is_approved === "Rejected"
                            ? "Rejected"
                            : ""
                        }}
                      </span>
                    </p>
                    <p>
                      <b>Status: </b>
                      <span
                        class="badge"
                        :class="
                          company.status == 'Active'
                            ? 'badge-success'
                            : 'badge-danger'
                        "
                      >
                        {{ company.status == "Active" ? "Active" : "Inactive" }}
                      </span>
                    </p>
                  </td>
                  <td
                    class="align-middle text-center"
                    width="170px"
                    v-if="permission.edit || permission.delete"
                  >
                    <button
                      v-if="permission.edit"
                      class="btn btn-primary btn-sm"
                      @click="edit(company.id)"
                    >
                      <feather-icon icon="EditIcon" size="16" />
                    </button>

                    <button
                      v-if="permission.delete"
                      class="btn btn-danger btn-sm"
                      style="margin-left: 4px"
                      @click="Delete(company.id)"
                    >
                      <feather-icon icon="TrashIcon" size="16" />
                    </button>
                  </td>
                </tr>

                <tr v-if="!companies.data.length">
                  <td class="text-center" colspan="6">
                    <h4>No data found!</h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <b-card-body>
            <div
              class="d-flex justify-content-between align-items-center"
              v-if="companies.meta"
            >
              <span class="text-muted"
                >Showing {{ companies.meta.from }} to {{ companies.meta.to }} of
                {{ companies.meta.total }} entries</span
              >
              <pagination
                :data="companies"
                @pagination-change-page="getResults"
                align="right"
                :show-disabled="true"
                :limit="5"
              ></pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="4">
        <b-card no-body v-if="permission.create || !form.id">
          <b-card-body :title="(!form.id ? 'Create' : 'Update') + ' Company'">
            <form @submit.prevent="!form.id ? save() : Update(form.id)">
              <b-row>
                <b-col lg="12">
                  <b-row>
                    <b-col cols="12" lg="12">
                      <div class="form-group">
                        <label>Company Name*</label>
                        <Input
                          v-model="form.name"
                          class="d-inline-block mr-1"
                          placeholder="Company Name"
                          type="text"
                        />
                        <has-error :form="form" field="name"></has-error>
                      </div>
                      <div class="form-group">
                        <label>Company Manpower*</label>
                        <Input
                          v-model="form.size"
                          class="d-inline-block mr-1"
                          placeholder="Employee Size"
                          type="number"
                        />
                      </div>
                      <div class="form-group">
                        <label>Email*</label>
                        <Input
                          v-model="form.email"
                          class="d-inline-block mr-1"
                          placeholder="company@gmail.com"
                          type="email"
                        />
                        <has-error :form="form" field="email"></has-error>
                      </div>

                      <b-row>
                        <b-col xl="12">
                          <div class="form-group">
                            <label>Phone*</label>
                            <Input
                              v-model="form.phone"
                              class="d-inline-block mr-1"
                              placeholder="01XXXXXXXXX"
                              type="number"
                            />
                            <has-error :form="form" field="phone"></has-error>
                          </div>
                        </b-col>
                        <b-col xl="6">
                          <div class="form-group position-relative">
                            <label>Password*</label>

                            <Input
                              v-model="form.password"
                              type="password"
                              password
                            />

                            <has-error
                              :form="form"
                              field="password"
                            ></has-error>
                          </div>
                        </b-col>
                        <b-col xl="6">
                          <div class="form-group">
                            <label>Confirm Password*</label>

                            <Input
                              v-model="form.password_confirmation"
                              type="password"
                              password
                            />
                          </div>
                          <has-error
                            :form="form"
                            field="password_confirmation"
                          ></has-error>
                        </b-col>
                        <b-col xl="6">
                          <div class="form-group">
                            <label>Division</label>
                            <Select
                              @on-change="getDistrict"
                              v-model="form.division_id"
                              placeholder="Select Division"
                              filterable
                              :clearable="true"
                            >
                              <Option
                                v-if="divisions.length"
                                v-for="(division, index) in divisions"
                                :key="index"
                                :value="division.id"
                                >{{ division.name }}
                              </Option>
                            </Select>
                          </div>
                        </b-col>
                        <b-col xl="6">
                          <div class="form-group">
                            <label for="Gender">Select District</label>
                            <Select
                              placeholder="Select your District"
                              v-model="form.district_id"
                              @on-change="getUpazila"
                            >
                              <Option
                                :value="district.id"
                                v-for="(district, dis_index) in form.districts"
                                :key="dis_index"
                                >{{ district.name }}
                              </Option>
                            </Select>
                          </div>
                        </b-col>
                      </b-row>

                      <div class="form-group">
                        <label for="Gender">Select Upazila</label>
                        <Select
                          placeholder="Select your Upazila"
                          v-model="form.upazila_id"
                        >
                          <Option
                            :value="upazila.id"
                            v-for="(upazila, upa_index) in form.upazilas"
                            :key="upa_index"
                            >{{ upazila.name }}
                          </Option>
                        </Select>
                      </div>
                      <div class="form-group">
                        <label>Company Address*</label>
                        <Input
                          v-model="form.address"
                          placeholder="Enter Company address"
                          type="text"
                        />
                        <has-error :form="form" field="address"></has-error>
                      </div>
                      <div class="form-group">
                        <label>Approval*</label>
                        <Select v-model="form.is_approved">
                          <Option :value="1">Approve</Option>
                          <Option :value="2">Pending</Option>
                          <Option :value="3">Rejected</Option>
                        </Select>
                      </div>
                      <div
                        class="div d-flex justify-content-center align-items-center p-2 border"
                      >
                        <img
                          :src="this.logo ? this.logo : getImage(160, 70)"
                          height="70"
                          width="160"
                          class="rounded mr-2 mb-1 mb-md-0"
                        />

                        <div class="image">
                          <b-form-file
                            id="icon"
                            placeholder="Upload Icon..."
                            @change="loadImage($event)"
                            accept=".jpeg,.png,.jpg"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <b-row class="pt-1">
                          <b-col lg="6">
                            <b-form-checkbox
                              name="status"
                              style="margin-top: 5px"
                              v-model="form.status"
                              switch
                              inline
                            >
                              Status
                            </b-form-checkbox>
                          </b-col>

                          <b-col lg="6">
                            <div class="text-right">
                              <b-button
                                variant="outline-primary"
                                class="mr-1"
                                @click="Clear()"
                                >Clear
                              </b-button>
                              <b-button
                                variant="primary"
                                type="submit"
                                :disabled="form.busy"
                              >
                                {{ form.id ? "Update" : "Add" }}
                              </b-button>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardBody,
  BFormFile,
} from "bootstrap-vue";
import { HasError, AlertError } from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        size: null,
        email: null,
        phone: null,
        password: null,
        password_confirmation: null,
        division_id: null,
        district_id: null,
        upazila_id: null,
        address: null,
        _method: "POST",
        is_approved: 2,
        logo: null,
        status: true,
        districts: {},
        upazilas: {},
      }),
      passwordInputType: "password",
      companies: {},
      image: null,
      logo: null,
      search: {
        search_data: "",
        status: "",
        paginate: 10,
        page: 1,
      },
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
    BCard,
    BCardBody,
    BFormFile,
  },

  mounted() {
    this.$store.dispatch("getDivision");
    this.getResults();
  },
  computed: {
    divisions() {
      return this.$store.getters.getDivision;
    },
    permission() {
      return this.$store.getters.getPermissions.company;
    },
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/app/company${queryParams}`)
        .then((res) => {
          this.companies = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    Clear() {
      this.form.id = null;
      this.form.name = null;
      this.form.size = null;
      this.form.email = null;
      this.form.phone = null;
      this.form.password = null;
      this.form.password_confirmation = null;
      this.form.division_id = null;
      this.form.district_id = null;
      this.form.upazila_id = null;
      this.form.address = null;
      this.form.is_approved = 2;
      this.form.logo = null;
      this.logo = null;
      this.form._method = "POST";
      this.form.status = false;
    },
    getDistrict() {
      axios
        .get("/candidate/district/get/" + this.form.division_id)
        .then((response) => {
          this.form.districts = response.data.districts;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    getUpazila() {
      axios
        .get("/candidate/upazilla/get/" + this.form.district_id)
        .then((response) => {
          this.form.upazilas = response.data.upazillas;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },

    save() {
      this.form
        .post("/app/company")
        .then((res) => {
          this.Clear();
          this.getResults();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    togglePasswordVisibility() {
      this.passwordInputType =
        this.passwordInputType === "password" ? "text" : "password";
    },
    edit(id) {
      axios
        .get("/app/company/" + id)
        .then((res) => {
          this.form.id = res.data.company.id;
          this.form.name = res.data.company.name;
          this.form.size = res.data.company.size;
          this.form.email = res.data.company.email;
          this.form.phone = res.data.company.phone;
          this.form.division_id = res.data.company.division_id;
          this.getDistrict();
          this.form.district_id = res.data.company.district_id;
          this.getUpazila();
          this.form.upazila_id = res.data.company.upazila_id;
          this.form.address = res.data.company.address;
          this.form.is_approved = res.data.company.is_approved;
          this.logo = res.data.company.logo
            ? this.$store.state.base_url + res.data.company.logo
            : this.getImage(160, 70);
          this.form.status = res.data.company.status == 1 ? true : false;
        })
        .catch((e) => {
          this.e(e.data.error);
        });
    },

    Update(id) {
      this.form._method = "PUT";
      this.form
        .post("/app/company/" + id)
        .then((res) => {
          this.Clear();
          this.getResults();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/app/company/" + id)
            .then((response) => {
              this.s(response.data.message);
              this.getResults();
            })
            .catch((e) => {
              this.e(e.data.error);
            });
        }
      });
    },
    loadImage(e) {
      this.form.logo = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.logo = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}

.padding {
  padding: 0.72rem 11px !important;
}
</style>
